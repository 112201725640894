import React from "react";
import Flickity from "react-flickity-component";
import SliderStyle from "../../components/styles/slider";

export const SliderCommodity = ({ children }) => {
  const flickityOptions = {
    contain: "true",
    prevNextButtons: false,
    cellAlign: "left",
    freeScroll: "true",
    pageDots: false
  };
  return (
    <div className="carousel-wrapper-commodity">
      <div className="carousel-container-commodity">
        <Flickity
          options={flickityOptions}
          className={"carousel"}
          elementType={"div"}
        >
          {children}
        </Flickity>
      </div>
      <SliderStyle />
      <style jsx global>
        {`
          .carousel-wrapper-commodity {
            width: 100%;
            align-self: center;
          }
        `}
      </style>
    </div>
  );
};
